// i18next-extract-mark-ns-start internships

import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const Careers: React.FC<{}> = () => {
  const {t} = useI18next();
  const title = t('Internships');
  return (
    <div className="py-24 md:py-32">
      <SEO title={title} />
      <div className="container px-6 py-7 md:py-14 relative z-10 min-h-screen flex justify-center">
        <article className="prose prose-sm sm:prose xl:prose-lg max-w-full">
          <h1>{title}</h1>
          <Trans parent="p">
            We’re looking for passionate software engineering students to join the microapps gang
            for 12 month terms. We want someone who loves clean code, TDD, and pair programming. If
            you spend your spare time on open source projects, keep up to date on the latest
            developments and techniques, and love to hack on cool new projects just for fun, then
            keep reading!
          </Trans>
          <Trans parent="p">
            Do you hack on your own projects? Attend monthly meet ups? Build at Hackathons?
            Contribute to open source projects? If you do, tell us about it!
          </Trans>
          <h4>Teams you could work on:</h4>
          <ul>
            <Trans parent="li">MoonMail and MONEI API</Trans>
            <Trans parent="li">iOS and Android teams for both MoonMail or MONEI</Trans>
            <Trans parent="li">Security</Trans>
            <Trans parent="li">Data + Analytics</Trans>
            <Trans parent="li">Support + Apps</Trans>
            <Trans parent="li">Financial Operations</Trans>
            <Trans parent="li">Growth</Trans>
            <Trans parent="li">Site Reliability</Trans>
            <Trans parent="li">Data engineering</Trans>
            <Trans parent="li">Marketing</Trans>
            <Trans parent="li">User Onboarding</Trans>
          </ul>
          <Trans parent="p">
            We’re already working and accepting internships with{' '}
            <a
              title="IAESTE"
              href="http://www.iaeste.org/"
              target="_blank"
              rel="noopener noreferrer">
              IAESTE
            </a>
            , The International Association for Exchange of Students for Technical Experience. So if
            you don’t know where to go in your University, look for someone in charge of IAESTE in
            your area and they will definitely help you.
          </Trans>
          <Trans parent="p">
            We prefer students in their last year of University and with lots of interest in staying
            at microapps after finishing their internship.
          </Trans>
          <Trans parent="p">
            Schooling and 100 years of experience is not a requirement here at microapps. We feel
            that the best way to get experience and knowledge is not always in the typical
            classroom, but through practical experience, toil and passion. We pride ourselves in
            hiring motivated and creative individuals that truly love what they do. If you love code
            and want to work in a relaxed, open development environment, come join microapps!
          </Trans>
          <Trans parent="p">
            If you’re interested in any of the above positions, please send an email to{' '}
            <a href="mailto:hi@microapps.com">hi@microapps.com</a> with your C/V, GitHub username
            and Stack Overflow username.
          </Trans>
          <Trans parent="p">
            Coming from out of town? No worries! We’ll cover your roundtrip travel at the beginning
            and end of the term.
          </Trans>
          <Trans parent="p">
            *We typically receive 200+ students resumes each month, but we respond to every
            application! If you have a strict timeline greater than 3-4 weeks (other offer, Co-op
            program deadlines, etc), let us know in your cover letter and we’ll do our best to
            respond to you quickly.
          </Trans>
          <Trans parent="p">
            If you end up being part of our in-house scholarship holder team, you get from us:
          </Trans>
          <ul>
            <Trans parent="li">
              A highly motivated and talented team where you can learn a lot from
            </Trans>
            <Trans parent="li">Flexible timetable</Trans>
            <Trans parent="li">Create your own workstation with any type of equipment</Trans>
            <Trans parent="li">Snacks and drinks in the office</Trans>
            <Trans parent="li">
              Flat organizational structure that ensures your influence on products we build
            </Trans>
            <Trans parent="li">
              A beach 1 minute from work. Yes! We’re in front of the beach!!
            </Trans>
          </ul>
        </article>
      </div>
    </div>
  );
};

export default Careers;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "internships"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
